import axios from "axios";
import {parseCookies, setCookie} from 'nookies'
import * as Sentry from "@sentry/react";

export const redtrackSubmitInit = () => {
    (async () => {

        const [campaignId, hasCampaignId] = getCampaignId();

        if (hasCampaignId) {
            const [clickId, _] = await preInit(campaignId);
            if (clickId !== "") {
                await preView(clickId);
            }
        }

        if (hasRedtrackData()) {
            if (!hasCampaignId) {
                await preClick();
            }
            await postView();
        }
    })();
}

export const redtrackInit = () => {
    const [campaignId, hasCampaign] = getCampaignId();
    if (hasCampaign) {
        (async () => {
            const [clickId, redHash] = await preInit(campaignId);
            if (clickId !== "") {
                await preView(clickId);
            }
        })();
    }
};


export const hasRedtrackData = () => {
    const [campaignId, clickId, redHash] = getRedValues();
    if (clickId && clickId !== "" && clickId !== "undefined") {
        return true;
    }
    return false;
}

export const getPreJs = async (campaignId) => {
    try {
        const response = await axios.post("/api/red/pre/js?c=" + campaignId);
        return response.status >= 200 && response.status < 300;
    } catch (er) {
        Sentry.captureException(er, {
            tags: {
                method: "api",
                place: "redtrack"
            }
        });

    }
    return "";
}

export const preInit = async (campaignId) => {

    const urlParams = new URLSearchParams(window.location.search);
    const rtkcid = urlParams.get('rtkcid');

    if (rtkcid && rtkcid !== "undefined" && rtkcid.length > 5) {
        const [_1, _2, redHash] = getRedValues();
        saveRedValues([redHash, campaignId, rtkcid]);
        return ["", ""]
    }

    const qsValues = {};
    const qsIgnore = ["rtkcid", "rtkcmpid"];
    urlParams.forEach((value, key, parent) => {
        if (qsIgnore.indexOf(key) < 0) {
            qsValues[key] = value;
        }
    });

    try {
        const response = await axios.post("/api/red/pre/init/" + campaignId, {qs: qsValues});
        if (response.status >= 200 && response.status < 300) {
            if ("clickId" in response.data) {
                saveRedValues([response.data["redHash"], campaignId, response.data["clickId"]]);
                return [response.data["clickId"], response.data["redHash"]];
            }
        }
    } catch (er) {
        Sentry.captureException(er, {
            tags: {
                method: "api",
                place: "redtrack"
            }
        });
    }

    return ["", ""];
}


export const preView = async (clickId) => {
    try {
        const response = await axios.post(`/api/red/pre/view/${clickId}`);
        return response.status >= 200 && response.status < 300;
    } catch (er) {
        Sentry.captureException(er, {
            tags: {
                method: "api",
                place: "redtrack"
            }
        });
    }
}


export const preClick = async () => {
    try {

        const [campaignId, clickId, redHash] = getRedValues();
        const allCookies = parseCookies();
        if ("red-pre-clicked" in allCookies && allCookies['red-pre-clicked'] === "y") {
            console.log(" > preclick was filtered out");
            return true;
        }
        if (clickId !== "") {
            setCookie(null, 'red-pre-clicked', "y", {
                maxAge: 30 * 60,
                path: '/',
            });

            const response = await axios.post(`/api/red/pre/click/${clickId}`, {redHash});
            saveRedValues([response.data.redHash, campaignId, response.data.clickId]);

            return response.status >= 200 && response.status < 300;
        }
    } catch (er) {
        Sentry.captureException(er, {
            tags: {
                method: "api",
                place: "redtrack"
            }
        });
    }

    return true;
}

export const getPostJs = async () => {
    try {
        const [campaignId, clickId, redHash] = getRedValues();
        if (campaignId && campaignId !== "") {
            const response = await axios.post(`/api/red/post/js?c=${campaignId}`, {redHash});
            return response.status >= 200 && response.status < 300;
        }
        return true;
    } catch (err) {
        Sentry.captureException(er, {
            tags: {
                method: "api",
                place: "redtrack"
            }
        });
    }

    return false;
}

export const redClick = async (userUid, rType) => {
    try {
        const [campaignId, clickId, redHash] = getRedValues();
        if (clickId && clickId !== "") {
            const response = await axios.post(`/api/red/post/click/${clickId}`, {redHash, userUid, rType});
            return response.status >= 200 && response.status < 300;
        }
    } catch (er) {
        Sentry.captureException(er, {
            tags:{
                method: "api",
                place: "redtrack"
            }
        });
    }
    return true;
}

export const postView = async () => {
    try {
        const [campaignId, clickId, redHash] = getRedValues();
        if (clickId && clickId !== "") {
            const response = await axios.post(`/api/red/post/view/${clickId}`, {redHash});
            return response.status >= 200 && response.status < 300;
        }
        return true;
    }catch (er){
        Sentry.captureException(er, {
            tags:{
                method: "api",
                place: "redtrack"
            }
        });
    }
}

export const redHasClickId = () => {
    const [campaignId, clickId, redHash] = getRedValues();

    return clickId && clickId !== "" && clickId !== "undefined";
}

const getCampaignId = () => {
    const cookies = parseCookies();
    let campaignId = cookies["rtkcmpid"];
    if (campaignId && campaignId !== "" && campaignId.length > 5) {
        return [campaignId, false];
    }

    const urlParams = new URLSearchParams(window.location.search);
    campaignId = urlParams.get('rtkcmpid');
    if (campaignId && campaignId !== "" && campaignId.length > 5) {
        setCookie(null, 'rtkcmpid', campaignId, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
        })
        return [campaignId, true];
    }

    return ["604a948bc7eae60001b88927", true];
}

const getRedValues = () => {
    const cookies = parseCookies();
    const clickId = cookies["rtkclkid"];
    const campaignId = cookies["rtkcmpid"];
    const redHash = cookies["redhash"];

    return [campaignId, clickId, redHash];
}

const saveRedValues = ([redHash, cmpId, clkId]) => {
    if (redHash && redHash !== "undefined") {
        setCookie(null, "redhash", redHash, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
        });
    }

    if (cmpId && cmpId !== "undefined") {
        setCookie(null, "rtkcmpid", cmpId, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
        });
    }

    if (clkId && clkId !== "undefined") {
        setCookie(null, "rtkclkid", clkId, {
            maxAge: 30 * 24 * 60 * 60,
            path: '/',
        });
    }
}
